import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import authGuard from "./guard/auth";
import publicGuard from "./guard/public";
import { paginatedPageQueryParams } from "sx-shared-core/src/mixins/page";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        name: "home",
        path: "/",
        component: () => import("@/views/app/auth/IndexView.vue"),
        redirect: "/dashboard",
        children: [
            {
                name: "login",
                path: "login",
                beforeEnter: publicGuard,
                component: () => import("@/views/app/auth/LoginView.vue"),
            },
        ],
    },
    {
        path: "/app",
        component: () => import("@/views/app/Index.vue"),
        beforeEnter: authGuard,
        redirect: "/app/dashboard",
        children: [
            {
                name: "dashboard",
                path: "/dashboard",
                redirect: { name: "training-class.index" },
            },
            {
                path: "/booking",
                component: () =>
                    import("@/views/app/booking/BookingParentView.vue"),
                children: [
                    {
                        name: "booking.create",
                        path: "create",
                        component: () =>
                            import("@/views/app/booking/BookingCreateView.vue"),
                    },
                    {
                        name: "booking.index",
                        path: "",
                        props: paginatedPageQueryParams({
                            period: "month",
                            limit: "100",
                        }),
                        component: () =>
                            import("@/views/app/booking/BookingIndexView.vue"),
                    },
                    {
                        path: ":id",
                        component: () =>
                            import(
                                "@/views/app/booking/BookingShowParentView.vue"
                            ),
                        children: [
                            {
                                name: "booking.show",
                                path: "",
                                component: () =>
                                    import(
                                        "@/views/app/booking/BookingShowView.vue"
                                    ),
                            },
                            {
                                name: "booking.item.create.public",
                                path: "item/create-public-course",
                                props: paginatedPageQueryParams({
                                    period: "month",
                                    classType: "all",
                                }),
                                component: () =>
                                    import(
                                        "@/views/app/booking/item/BookingItemCreatePublicCourseView.vue"
                                    ),
                            },
                            {
                                name: "booking.item.create.private",
                                path: "item/create-private-course",
                                component: () =>
                                    import(
                                        "@/views/app/booking/item/BookingItemCreatePrivateCourse.vue"
                                    ),
                            },
                            {
                                name: "booking.ticket.create",
                                path: "ticket-create",
                                component: () =>
                                    import(
                                        "@/views/app/booking/BookingTicketCreateView.vue"
                                    ),
                            },
                        ],
                    },
                ],
            },
            {
                path: "/customer-contact",
                component: () =>
                    import(
                        "@/views/app/customer-contact/CustomerContactParentView.vue"
                    ),
                children: [
                    {
                        name: "customer-contact.index",
                        path: "",
                        redirect: { name: "dashboard" },
                    },
                    {
                        name: "customer-contact.show",
                        path: ":id",
                        component: () =>
                            import(
                                "@/views/app/customer-contact/CustomerContactShowView.vue"
                            ),
                    },
                ],
            },
            {
                path: "/change-request",
                component: () =>
                    import(
                        "@/views/app/change-request/ChangeRequestParentView.vue"
                    ),
                children: [
                    {
                        name: "change-request.index",
                        path: "",
                        props: paginatedPageQueryParams({ period: "month" }),
                        component: () =>
                            import(
                                "@/views/app/change-request/ChangeRequestIndexView.vue"
                            ),
                    },
                    {
                        name: "change-request.create",
                        path: "create",
                        component: () =>
                            import(
                                "@/views/app/change-request/ChangeRequestCreateView.vue"
                            ),
                    },
                    {
                        name: "change-request.show",
                        path: ":id",
                        component: () =>
                            import(
                                "@/views/app/change-request/ChangeRequestShowView.vue"
                            ),
                    },
                ],
            },
            {
                path: "/course",
                component: () =>
                    import("@/views/app/course/CourseParentView.vue"),
                children: [
                    {
                        name: "course.index",
                        path: "",
                        props: paginatedPageQueryParams({
                            subjectTitle: "",
                            title: "",
                        }),
                        component: () =>
                            import("@/views/app/course/CourseIndexView.vue"),
                    },
                    {
                        name: "course.show",
                        path: ":id",
                        component: () =>
                            import("@/views/app/course/CourseShowView.vue"),
                    },
                ],
            },
            {
                path: "/customer",
                component: () =>
                    import("@/views/app/customer/CustomerParentView.vue"),
                children: [
                    {
                        name: "customer.index",
                        path: "",
                        props: paginatedPageQueryParams({
                            sort: "updatedAt:desc,name:asc",
                        }),
                        component: () =>
                            import(
                                "@/views/app/customer/CustomerIndexView.vue"
                            ),
                    },
                    {
                        name: "customer.show",
                        path: ":id",
                        component: () =>
                            import("@/views/app/customer/CustomerShowView.vue"),
                    },
                ],
            },
            {
                name: "debtor.index",
                path: "/debtor",
                component: () =>
                    import("@/views/app/debtor/DebtorIndexView.vue"),
            },
            {
                path: "/email-log",
                component: () =>
                    import("@/views/app/email-log/EmailLogParentView.vue"),
                children: [
                    {
                        name: "email-log.index",
                        path: "",
                        props: paginatedPageQueryParams({
                            limit: 100,
                            sort: "createdAt:desc",
                        }),
                        component: () =>
                            import(
                                "@/views/app/email-log/EmailLogIndexView.vue"
                            ),
                    },
                    {
                        name: "email-log.show",
                        path: ":id",
                        component: () =>
                            import(
                                "@/views/app/email-log/EmailLogShowView.vue"
                            ),
                    },
                ],
            },
            {
                path: "/invoice",
                component: () =>
                    import("@/views/app/invoice/InvoiceParentView.vue"),
                children: [
                    {
                        name: "invoice.index",
                        path: "",
                        props: paginatedPageQueryParams({
                            sort: "documentDate:desc",
                        }),
                        component: () =>
                            import("@/views/app/invoice/InvoiceIndexView.vue"),
                    },
                    {
                        name: "invoice.show",
                        path: ":id",
                        component: () =>
                            import("@/views/app/invoice/InvoiceShowView.vue"),
                    },
                ],
            },
            {
                path: "/public-schedule",
                component: () =>
                    import(
                        "@/views/app/public-schedule/PublicScheduleParentView.vue"
                    ),
                children: [
                    {
                        name: "public-schedule.index",
                        path: "",
                        props: paginatedPageQueryParams({
                            sort: "class.sessions.startsAt:desc",
                        }),
                        component: () =>
                            import(
                                "@/views/app/public-schedule/PublicScheduleIndexView.vue"
                            ),
                    },
                    {
                        name: "public-schedule.create",
                        path: "create",
                        component: () =>
                            import(
                                "@/views/app/public-schedule/PublicScheduleCreateView.vue"
                            ),
                    },
                    {
                        name: "public-schedule.show",
                        path: ":id",
                        component: () =>
                            import(
                                "@/views/app/public-schedule/PublicScheduleShowView.vue"
                            ),
                    },
                ],
            },
            {
                path: "/purchase-order",
                component: () =>
                    import(
                        "@/views/app/purchase-order/PurchaseOrderParentView.vue"
                    ),
                children: [
                    {
                        name: "purchase-order.index",
                        path: "",
                        props: paginatedPageQueryParams({
                            reference: "",
                            sort: "raisedAt:desc",
                        }),
                        component: () =>
                            import(
                                "@/views/app/purchase-order/PurchaseOrderIndexView.vue"
                            ),
                    },
                    {
                        name: "purchase-order.show",
                        path: ":id",
                        component: () =>
                            import(
                                "@/views/app/purchase-order/PurchaseOrderShowView.vue"
                            ),
                    },
                ],
            },
            {
                name: "subject",
                path: "/subject",
                props: paginatedPageQueryParams(),
                component: () =>
                    import("@/views/app/subject/SubjectIndexView.vue"),
                children: [
                    {
                        name: "subject.create",
                        path: "create",
                        component: () =>
                            import("@/views/app/subject/SubjectCreateView.vue"),
                    },
                ],
            },
            {
                path: "/supplier",
                component: () =>
                    import("@/views/app/supplier/SupplierParentView.vue"),
                children: [
                    {
                        name: "supplier.index",
                        path: "",
                        props: paginatedPageQueryParams(),
                        component: () =>
                            import(
                                "@/views/app/supplier/SupplierIndexView.vue"
                            ),
                    },
                    {
                        name: "supplier.create",
                        path: "create",
                        component: () =>
                            import(
                                "@/views/app/supplier/SupplierCreateView.vue"
                            ),
                    },
                    {
                        name: "supplier.show",
                        path: ":id",
                        component: () =>
                            import("@/views/app/supplier/SupplierShowView.vue"),
                    },
                ],
            },
            {
                path: "/ticket",
                component: () =>
                    import("@/views/app/ticket/TicketParentView.vue"),
                children: [
                    {
                        name: "ticket.index",
                        path: "",
                        props: paginatedPageQueryParams({
                            limit: 100,
                            mine: 1,
                            sort: "createdAt:desc",
                        }),
                        component: () =>
                            import("@/views/app/ticket/TicketIndexView.vue"),
                    },
                    {
                        name: "ticket.show",
                        path: ":id",
                        component: () =>
                            import("@/views/app/ticket/TicketShowView.vue"),
                    },
                ],
            },
            {
                path: "/trainer",
                component: () =>
                    import("@/views/app/trainer/TrainerParentView.vue"),
                children: [
                    {
                        name: "trainer.index",
                        path: "",
                        props: paginatedPageQueryParams(),
                        component: () =>
                            import("@/views/app/trainer/TrainerIndexView.vue"),
                    },
                    {
                        name: "trainer.create",
                        path: "create",
                        component: () =>
                            import("@/views/app/trainer/TrainerCreateView.vue"),
                    },
                    {
                        name: "trainer.show",
                        path: ":id",
                        component: () =>
                            import("@/views/app/trainer/TrainerShowView.vue"),
                    },
                ],
            },
            {
                path: "/trainer-course",
                component: () =>
                    import(
                        "@/views/app/trainer-course/TrainerCourseParentView.vue"
                    ),
                children: [
                    {
                        name: "trainer-course.index",
                        path: "",
                        props: paginatedPageQueryParams({ limit: 100 }),
                        component: () =>
                            import(
                                "@/views/app/trainer-course/TrainerCourseIndexView.vue"
                            ),
                    },
                ],
            },
            {
                path: "/training-class",
                component: () =>
                    import(
                        "@/views/app/training-class/TrainingClassParentView.vue"
                    ),
                children: [
                    {
                        name: "training-class.index",
                        path: "",
                        component: () =>
                            import(
                                "@/views/app/training-class/TrainingClassIndexView.vue"
                            ),
                    },
                    {
                        name: "training-class.create",
                        path: "create",
                        component: () =>
                            import(
                                "@/views/app/training-class/TrainingClassCreateView.vue"
                            ),
                    },
                    {
                        name: "training-class.show",
                        path: ":id",
                        component: () =>
                            import(
                                "@/views/app/training-class/TrainingClassShowView.vue"
                            ),
                    },
                ],
            },
            {
                path: "/user",
                component: () => import("@/views/app/user/UserParentView.vue"),
                children: [
                    {
                        name: "user.index",
                        path: "",
                        props: paginatedPageQueryParams({
                            sort: "createdAt:desc",
                        }),
                        component: () =>
                            import("@/views/app/user/UserIndexView.vue"),
                    },
                    {
                        name: "user.create",
                        path: "create",
                        component: () =>
                            import("@/views/app/user/UserCreateView.vue"),
                    },
                    {
                        path: ":id",
                        component: () =>
                            import("@/views/app/user/UserShowParentView.vue"),
                        children: [
                            {
                                name: "user.show",
                                path: "",
                                component: () =>
                                    import("@/views/app/user/UserShowView.vue"),
                            },
                        ],
                    },
                ],
            },
            {
                name: "email-build-context",
                path: "/email-build-context",
                component: () =>
                    import(
                        "@/views/app/email-build-context/EmailBuildContextParentView.vue"
                    ),
                children: [
                    {
                        name: "email-build-context.show",
                        path: ":id",
                        component: () =>
                            import(
                                "@/views/app/email-build-context/EmailBuildContextShowView.vue"
                            ),
                    },
                ],
            },
            {
                path: "/email-template",
                component: () =>
                    import(
                        "@/views/app/email-template/EmailTemplateParentView.vue"
                    ),
                children: [
                    {
                        name: "email-template.index",
                        path: "",
                        props: paginatedPageQueryParams({
                            sort: "updatedAt:desc",
                        }),
                        component: () =>
                            import(
                                "@/views/app/email-template/EmailTemplateIndexView.vue"
                            ),
                        children: [
                            {
                                name: "email-template.create",
                                path: "create",
                                component: () =>
                                    import(
                                        "@/views/app/email-template/EmailTemplateCreateView.vue"
                                    ),
                            },
                        ],
                    },
                    {
                        name: "email-template.show",
                        path: ":id",
                        component: () =>
                            import(
                                "@/views/app/email-template/EmailTemplateShowView.vue"
                            ),
                        children: [
                            {
                                name: "email-template.update",
                                path: "update",
                                component: () =>
                                    import(
                                        "@/views/app/email-template/EmailTemplateUpdateView.vue"
                                    ),
                            },
                        ],
                    },
                ],
            },
            {
                name: "email-sample-data.show",
                path: "/email-sample-data",
                component: () =>
                    import(
                        "@/views/app/email-sample-data/EmailSampleDataShowView.vue"
                    ),
            },
            {
                path: "/workflow",
                component: () =>
                    import("@/views/app/workflow/WorkflowParentView.vue"),
                children: [
                    {
                        name: "workflow.index",
                        path: "",
                        props: paginatedPageQueryParams({
                            sort: "updatedAt:desc",
                        }),
                        component: () =>
                            import(
                                "@/views/app/workflow/WorkflowIndexView.vue"
                            ),
                        children: [
                            {
                                name: "workflow.create",
                                path: "create",
                                component: () =>
                                    import(
                                        "@/views/app/workflow/WorkflowCreateView.vue"
                                    ),
                            },
                        ],
                    },

                    {
                        name: "workflow.show",
                        path: ":id",
                        component: () =>
                            import("@/views/app/workflow/WorkflowShowView.vue"),
                    },
                ],
            },
        ],
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior: (to, from, savedPosition) => {
        if (to.hash) return { selector: to.hash };
        if (savedPosition) return savedPosition;

        return { x: 0, y: 0 };
    },
    routes,
});

export default router;
